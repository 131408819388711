/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "./assets/scss/variable.scss";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
h2.feature-heaing {
  font-size: 24px;
  font-weight: 500;
}
.btn-theme {
  font-size: 14px;
  background: var(--dark-7);
  border: 0;
  color: var(--white-10);
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid;
  display: flex;
  align-items: center;
  gap: 4px;
  &:hover {
    background: var(--white-10);
    color: var(--dark-7);
  }
  &:disabled {
    opacity: 0.5;
  }
}
