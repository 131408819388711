:root {
  --dark-1: #000;
  --dark-2: #474752;
  --dark-3: #6b6a6e;
  --dark-4: #22222e;
  --dark-5: #22222f;
  --dark-6: #67686a;
  --dark-7: #1d1d1d;

  // -----------------  black Colors -------------------
  --black-10: #000;

  // -----------------  Tertiary Colors -------------------
  --tertiary-1: #f26d0c;
  --tertiary-2: #fdd649;
  --tertiary-25: #fdd64980;
  --tertiary-3: #3dd598;
  --tertiary-4: #ffab48;
  --tertiary-5: #ff575f;
  --tertiary-6: #ff0303;
  --tertiary-7: #ce0000;

  // -----------------  light Colors -------------------
  --light-1: #c3c3c3;
  --light-2: #d3d3d6;
  --light-3: #96a7af;
  --light-4: #f1f5f7;
  --light-5: #e1e1e1;
  --light-6: rgba(236, 236, 236, 0.7607843137);

  // -----------------  white Colors -------------------
  --white-4: #ffffff66;
  --white-5: #ffffff80;
  --white-7: #ffffffb3;
  --white-9: #ffffffe6;
  --white-10: #fff;
}
